export namespace DateNS {
  export enum DYNAMIC_DATE {
    today = 'today',
    yesterday = 'yesterday',
    currentWeek = 'current-week',
    lastWeek = 'last-week',
    currentMonth = 'current-month',
    lastMonth = 'last-month',
    last7Days = 'last-seven-days',
    last15Days = 'last-15-days',
    last30Days = 'last-thirty-days',
    last90Days = 'last-ninety-days',
    custom = 'custom',
    mom = 'mom',
    lastXDays = 'last-x-days',
    specificDates = 'specific-dates',
  }
  export interface DateState {
    mode: DYNAMIC_DATE
    customerTimestamp: [number, number] | null
  }
  export interface CompareDateState extends DateState {
    isCompareDate: boolean
  }
}
