import getSymbolFromCurrency from 'currency-symbol-map'
import { toNumber } from 'lodash-es'
import { COUNTRY_CURRENCY } from '@/configs/strategy'
import { divideNumber, logBase } from './util'

/**
 * format number and addBlank
 * 0-9: 6 -> 6
 * 10-9,999: 6666 -> 6,666
 * 10,000-9,999,999: 6,666,666 -> 6,666K
 * 10,000,000-9,999,999,999: 6,666,666,666 -> 6,666,666M
 * 10,000,000,000-MAX: 6,666,666,666,666 -> 6,666,666,666B
 * @param num origin (possible) number
 * @param digits controls decimal places
 */
export function formatInt(num: any, digits = 0, bigFormat = false) {
  if (num === 0) return '0'
  if (typeof num !== 'number') return '--'

  if (!bigFormat) {
    return `${formatNumberWithCommas(num.toFixed(digits))}`
  }

  const absoluteVal = Math.abs(num)
  const isNegative = num < 0
  let result = ''
  let suffix = ''

  if (absoluteVal < 10 * 1000) result = absoluteVal.toFixed(digits)
  else if (absoluteVal < 10 * 1000 * 1000) {
    result = Number(absoluteVal / 1000).toFixed(0)
    suffix = 'K'
  } else if (absoluteVal < 10 * 1000 * 1000 * 1000) {
    result = Number(absoluteVal / 1000 / 1000).toFixed(0)
    suffix = 'M'
  } else {
    result = Number(absoluteVal / 1000 / 1000 / 1000).toFixed(0)
    suffix = 'B'
  }

  result = formatNumberWithCommas(result)
  return `${isNegative ? '-' : ''}${result}${suffix}`
}

export function formatFloat(num: any, digits = 2, unit = '') {
  if (num === 0) return '0'
  if (typeof num !== 'number') return '--'
  const symbol = getSymbolFromCurrency(unit) || ''
  return `${symbol}${formatNumberWithCommas(num.toFixed(digits))}`
}

export function formatPercent(num: any, digits = 2) {
  if (typeof num !== 'number') return '--'
  return `${(num * 100).toFixed(digits)}%`
}

export function formatValue(
  num: any,
  option: {
    type: string
    digits?: number
    unit?: string
    bigFormat?: boolean
  },
) {
  const { type, digits, unit, bigFormat } = option
  switch (type) {
    case 'int':
      return formatInt(num, digits, bigFormat)
    case 'percent':
      return formatPercent(num, digits)
    case 'float':
    case 'amount':
    default:
      return formatFloat(num, digits, unit)
  }
}

export function formatNumberWithCommas(
  number: string | number,
  decimal = 4,
): string {
  const [integerPart, decimalPart] = number.toString().split('.')
  if (decimalPart === undefined)
    return Number(number).toLocaleString('en-US', {
      maximumFractionDigits: decimal,
    })
  return `${parseInt(integerPart, 10).toLocaleString('en-US')}.${decimalPart.slice(0, decimal)}`
}

export function formatDynamicNumber(num: any, digits = 2) {
  const baseNum = 10 ** digits
  return Math.round(num * baseNum) / baseNum
}

export function formatNumberValue(num: string | number, digits = 2) {
  return formatNumberWithCommas(
    formatDynamicNumber(toNumber(num), digits),
    digits,
  )
}

export function isNumberic(num: any) {
  return /^\d+$/.test(num)
}

export function formatCurrency(
  num: string | number,
  countryCode: string,
  digits = 2,
) {
  const strCurrency = COUNTRY_CURRENCY[countryCode]
  return `${formatNumberWithCommas(num, digits)} ${strCurrency}`
}

export function formatterMoneyCurrency(number: number) {
  const lstPrefix = ['', 'k', 'm', 'b']
  let idx = number >= 1000 ? logBase(number, 1000) : 0
  let divideNum = number
  do {
    divideNum = divideNumber(number, 1000 ** idx, 2)
    if (divideNum === 0) idx -= 1
  } while (divideNum === 0 && idx >= 0)
  return `${divideNum}${lstPrefix[idx] ?? ''}`
}

export function formatCommonNumber(
  number: any,
  countryCode?: string,
  digits = 2,
): string {
  const [integerPart, decimalPart] = number.toString().split('.')
  if (countryCode === undefined) {
    if (decimalPart === undefined) return formatNumberWithCommas(number)
    return `${parseInt(integerPart, 10).toLocaleString('en-US')}.${decimalPart.slice(0, digits)}`
  }
  const strCurrency = COUNTRY_CURRENCY[countryCode]
  if (decimalPart === undefined) return formatCurrency(number, countryCode, 0)
  return `${parseInt(integerPart, 10).toLocaleString('en-US')}.${decimalPart.slice(0, digits)} ${strCurrency}`
}
