import {
  castArray,
  eq,
  gt,
  gte,
  inRange,
  includes,
  lt,
  lte,
  some,
} from 'lodash-es'
import { METRIC_ENUMS } from './campaign'
import { COMPONENT_TYPE } from './common'
import { escapeRegexpString } from '@/utils/util'

export enum ADVANCED_FIELDS {
  KEYWORD = 'keyword_normalize',
  ID = 'id',
  NAME = 'name',
  STATUS = 'status',
  CHANNEL = 'channel',
  MATCH_TYPE = 'match_type',
  BID_PRICE = 'bid_price',
  SUGGESTED_BID_PRICE = 'suggested_bid_price',
  STRATEGIES = 'strategies',
}
export enum CAM_ADVANCED_FIELDS {
  // SKU_ID = 'sku_id',
  // CAMPAIGN_ID = 'campaign_id',
  // AD_NAME = 'ad_name',
  // CAMPAIGN_NAME = 'campaign_name',
  // STATUS = 'status',
  // PRODUCT_PLACEMENT = 'product_placement',
  // BIDDING_STRATEGY = 'bidding_strategy',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  // TARGET_AUDIENCE_TYPE = 'target_audience_type',
  // DAILY_BUDGET = 'daily_budget',
  // MAX_DAILY_BUDGET = 'max_daily_budget',
}

export const ADVANCED_CONFIG: Record<string, any> = {
  [ADVANCED_FIELDS.ID]: {
    type: COMPONENT_TYPE.STRING,
    expression: 'metric<=500',
    supportOperator: 7920,
  },
  [ADVANCED_FIELDS.NAME]: {
    type: COMPONENT_TYPE.STRING,
    expression: 'metric<=500',
    supportOperator: 7920,
  },
  keyword: {
    [ADVANCED_FIELDS.KEYWORD]: {
      type: COMPONENT_TYPE.STRING,
      expression: 'metric<=500',
      supportOperator: 7920,
    },

    [ADVANCED_FIELDS.STRATEGIES]: {
      type: COMPONENT_TYPE.STRING,
      expression: 'metric<=500',
      supportOperator: 7920,
    },
    [ADVANCED_FIELDS.CHANNEL]: {
      type: COMPONENT_TYPE.DROPDOWN,
      expression: "includes(['shopee_product_ads','shopee_shop_ads'], metric)",
      options: ['shopee_product_ads', 'shopee_shop_ads'],
      supportOperator: 1584,
    },
    [ADVANCED_FIELDS.STATUS]: {
      type: COMPONENT_TYPE.DROPDOWN,
      expression: "includes(['ongoing','paused','reserved'], metric)",
      options: ['ongoing', 'paused', 'reserved'],
      supportOperator: 1584,
    },
    [ADVANCED_FIELDS.MATCH_TYPE]: {
      type: COMPONENT_TYPE.DROPDOWN,
      expression: "includes(['broad','exact'], metric)",
      options: ['broad', 'exact'],
      supportOperator: 1584,
    },
    [ADVANCED_FIELDS.BID_PRICE]: {
      type: COMPONENT_TYPE.DECIMAL,
      expression: 'metric>=0 && metric<=999999999999.99',
      supportOperator: 1855,
    },
    [ADVANCED_FIELDS.SUGGESTED_BID_PRICE]: {
      type: COMPONENT_TYPE.DECIMAL,
      expression: 'metric>=0 && metric<=999999999999.99',
      supportOperator: 1855,
    },
  },
  [METRIC_ENUMS.SPEND]: {
    type: COMPONENT_TYPE.DECIMAL,
    expression: 'metric>=0 && metric<=999999999999.99',
    postFix: 'currency',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.ROAS]: {
    type: COMPONENT_TYPE.DECIMAL,
    expression: 'metric>=0 && metric<=999999999999.99',
    postFix: '',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.PAID_GMV]: {
    type: COMPONENT_TYPE.DECIMAL,
    expression: 'metric>=0 && metric<=999999999999.99',
    postFix: 'currency',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.CPC]: {
    type: COMPONENT_TYPE.DECIMAL,
    expression: 'metric>=0 && metric<=999999999999.99',
    postFix: 'currency',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.CPO]: {
    type: COMPONENT_TYPE.DECIMAL,
    expression: 'metric>=0 && metric<=999999999999.99',
    postFix: 'currency',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.CPM]: {
    type: COMPONENT_TYPE.DECIMAL,
    expression: 'metric>=0 && metric<=999999999999.99',
    postFix: 'currency',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.DIRECT_PAID_GMV]: {
    type: COMPONENT_TYPE.DECIMAL,
    expression: 'metric>=0 && metric<=999999999999.99',
    postFix: 'currency',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.DIRECT_ROAS]: {
    type: COMPONENT_TYPE.DECIMAL,
    expression: 'metric>=0 && metric<=999999999999.99',
    postFix: '',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.CLICK_THROUGH_RATE]: {
    type: COMPONENT_TYPE.DECIMAL,
    expression: 'metric>=0 && metric<=100',
    postFix: '%',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.CONVERSION_RATE]: {
    type: COMPONENT_TYPE.DECIMAL,
    expression: 'metric>=0 && metric<=100',
    postFix: '%',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.AOV]: {
    type: COMPONENT_TYPE.INTEGER,
    expression: 'metric>=0 && metric<=999999999999',
    postFix: '',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.CLICKS]: {
    type: COMPONENT_TYPE.INTEGER,
    expression: 'metric>=0 && metric<=999999999999',
    postFix: '',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.IMPRESSIONS]: {
    type: COMPONENT_TYPE.INTEGER,
    expression: 'metric>=0 && metric<=999999999999',
    postFix: '',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.UNITS_SOLD]: {
    type: COMPONENT_TYPE.INTEGER,
    expression: 'metric>=0 && metric<=999999999999',
    postFix: '',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.ORDERS]: {
    type: COMPONENT_TYPE.INTEGER,
    expression: 'metric>=0 && metric<=999999999999',
    postFix: '',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.DIRECT_UNITS_SOLD]: {
    type: COMPONENT_TYPE.INTEGER,
    expression: 'metric>=0 && metric<=999999999999',
    postFix: '',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.AVERAGE_RANKING]: {
    type: COMPONENT_TYPE.INTEGER,
    expression: 'metric>=0 && metric<=999999999999',
    postFix: '',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.SEARCH_VOLUME]: {
    type: COMPONENT_TYPE.INTEGER,
    expression: 'metric>=0 && metric<=999999999999',
    postFix: '',
    supportOperator: 1855,
  },
  [METRIC_ENUMS.ADD_TO_CART_UNITS]: {
    type: COMPONENT_TYPE.DECIMAL,
    expression: 'metric>=0 && metric<=999999999999.99',
    postFix: '',
    supportOperator: 1855,
  },
  // [PLATFORM.SHOPEE]: {
  //   [CAM_ADVANCED_FIELDS.STATUS]: {
  //     type: COMPONENT_TYPE.DROPDOWN,
  //     expression: "includes(['ongoing','paused'], metric)",
  //     options: ['ongoing', 'paused'],
  //     supportOperator: 1584,
  //   },
  // },
  // [PLATFORM.LAZADA]: {
  //   [CAM_ADVANCED_FIELDS.STATUS]: {
  //     type: COMPONENT_TYPE.DROPDOWN,
  //     expression: "includes(['ongoing','paused'], metric)",
  //     options: ['ongoing', 'paused'],
  //     supportOperator: 1584,
  //   },
  // },
  // [CAM_ADVANCED_FIELDS.DAILY_BUDGET]: {
  //   type: COMPONENT_TYPE.DECIMAL,
  //   expression: 'metric>=0 && metric<=999999999999',
  //   postFix: 'currency',
  //   supportOperator: 1855,
  // },
  // [CAM_ADVANCED_FIELDS.MAX_DAILY_BUDGET]: {
  //   type: COMPONENT_TYPE.DECIMAL,
  //   expression: 'metric>=0 && metric<=999999999999',
  //   postFix: 'currency',
  //   supportOperator: 1855,
  // },
  // [CAM_ADVANCED_FIELDS.PRODUCT_PLACEMENT]: {
  //   type: COMPONENT_TYPE.DROPDOWN,
  //   expression: "includes(['all','search_product', 'targeting'], metric)",
  //   options: ['all', 'search_product', 'targeting'],
  //   supportOperator: 1584,
  // },
  // [CAM_ADVANCED_FIELDS.BIDDING_STRATEGY]: {
  //   type: COMPONENT_TYPE.DROPDOWN,
  //   expression: "includes(['auto','manual','roi_two'], metric)",
  //   options: ['auto', 'manual', 'roi_two'],
  //   supportOperator: 1584,
  // },
  // [CAM_ADVANCED_FIELDS.TARGET_AUDIENCE_TYPE]: {
  //   type: COMPONENT_TYPE.DROPDOWN,
  //   expression: "includes(['auto','manual'], metric)",
  //   options: ['auto', 'manual'],
  //   supportOperator: 1584,
  // },
  [CAM_ADVANCED_FIELDS.START_DATE]: {
    type: COMPONENT_TYPE.DATETIME,
    expression: 'YYYY-MM-DD',
    supportOperator: 319,
  },
  [CAM_ADVANCED_FIELDS.END_DATE]: {
    type: COMPONENT_TYPE.DATETIME,
    expression: 'YYYY-MM-DD',
    supportOperator: 319,
  },
  // [CAM_ADVANCED_FIELDS.SKU_ID]: {
  //   type: COMPONENT_TYPE.STRING,
  //   expression: 'metric<=500',
  //   supportOperator: 7920,
  // },
  // [CAM_ADVANCED_FIELDS.CAMPAIGN_ID]: {
  //   type: COMPONENT_TYPE.STRING,
  //   expression: 'metric<=500',
  //   supportOperator: 7920,
  // },
  // [CAM_ADVANCED_FIELDS.CAMPAIGN_NAME]: {
  //   type: COMPONENT_TYPE.STRING,
  //   expression: 'metric<=500',
  //   supportOperator: 7920,
  // },
  // [CAM_ADVANCED_FIELDS.AD_NAME]: {
  //   type: COMPONENT_TYPE.STRING,
  //   expression: 'metric<=500',
  //   supportOperator: 7920,
  // },
}
export const removeVietnameseTones = (str: string) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, '') // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ')
  str = str.trim()
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/[!@%^*()+=<>?/.,:;'"&#[\]~$`|\-{}|\\]/g, ' ')
  return str
}
const ne = (value: any, other: any) => !eq(value, other)
const excludes = (value: any[], other: any) => !includes(value, other)
const between = (value: number, start: number, end: number) =>
  inRange(value, start, end + 0.01)
export const contain = (value: any, other: any) =>
  new RegExp(escapeRegexpString(removeVietnameseTones(other)), 'i').test(
    removeVietnameseTones(value),
  )
const notContain = (value: any, other: any) => !contain(value, other)
export const contains = (value: any, other: any) =>
  some(
    castArray(other).map(str => contain(value, str)),
    Boolean,
  )
const notContains = (value: any, other: any) => !contains(value, other)

export const MAP_OPERATORS: Record<string, any> = {
  '=': eq,
  '!=': ne,
  '>=': gte,
  '>': gt,
  '<=': lte,
  '<': lt,
  in: includes,
  not_in: excludes,
  between,
  contain,
  exclude: notContain,
  contains,
  excludes: notContains,
}
