import { METRIC_ENUMS } from '@/constants/campaign'

export const COUNTRY_CURRENCY: { [key: string]: any } = {
  vn: 'VND',
  th: 'THB',
  id: 'IDR',
  sg: 'SGD',
  my: 'MYR',
  ph: 'PHP',
}
export const HISTORICAL_METRIC: Record<string, string> = {
  [METRIC_ENUMS.SPEND]: '(sum)',
  [METRIC_ENUMS.UNITS_SOLD]: '(sum)',
  [METRIC_ENUMS.ROAS]: '(avg.)',
  [METRIC_ENUMS.PAID_GMV]: '(sum)',
  [METRIC_ENUMS.ORDERS]: '(sum)',
  [METRIC_ENUMS.CPC]: '(avg.)',
}
