import { PLATFORM } from './common'

export const KEY_CACHE = 'user_store'

export enum CampaignType {
  CAMPAIGN = 'campaign',
  ADS = 'ads',
}

export const DIFINED_TYPE: Record<string, any> = {
  lazada: CampaignType.CAMPAIGN,
  shopee: CampaignType.ADS,
}

export const DEFAULT_FILTER: Array<string> = ['name', 'id']

export const AD_PLACEMENT: Record<string, string> = {
  all: 'campaigns.main-tabs.placements.all',
  search_product: 'campaigns.main-tabs.placements.search',
  targeting: 'campaigns.main-tabs.placements.discovery',
}

export const BIDDING_METHOD: Record<string, string> = {
  auto: 'campaigns.main-tabs.bidding-method.auto',
  manual: 'campaigns.main-tabs.bidding-method.manual',
}

export const BIDDING_METHOD_SHOPEE_PRODUCT_ADS: Record<string, string> = {
  auto: 'campaigns.main-tabs.bidding-method.auto',
  manual: 'campaigns.main-tabs.bidding-method.manual',
  roi_two: 'campaigns.main-tabs.bidding-method.roi_two',
}

export const TARGET_AUDIENCE_TYPE: Record<string, string> = {
  auto: 'campaigns.main-tabs.target-audience-type.auto',
  manual: 'campaigns.main-tabs.target-audience-type.manual',
}

export const DEFAULT_MESSAGES: Record<string, string> = {
  [PLATFORM.LAZADA]: PLATFORM.LAZADA,
  [PLATFORM.SHOPEE]: `${PLATFORM.SHOPEE}-subkey`,
}

export const METRIC_CURRENCY: string[] = [
  'spend',
  'paid_gmv',
  'cpo',
  'cpm',
  'cpc',
  'direct_paid_gmv',
  'bid_price',
]

export enum METRIC_ENUMS {
  SPEND = 'spend',
  UNITS_SOLD = 'units_sold',
  DIRECT_UNITS_SOLD = 'direct_units_sold',
  ROAS = 'roas',
  DIRECT_ROAS = 'direct_roas',
  PAID_GMV = 'paid_gmv',
  DIRECT_PAID_GMV = 'direct_paid_gmv',
  CONVERSION_RATE = 'conversion_rate',
  CLICK_THROUGH_RATE = 'click_through_rate',
  CPM = 'cpm',
  CPC = 'cpc',
  CPO = 'cpo',
  CLICKS = 'clicks',
  IMPRESSIONS = 'impressions',
  ORDERS = 'orders',
  ADD_TO_CART_UNITS = 'add_to_cart_units',
  AOV = 'aov',
  AVERAGE_RANKING = 'average_ranking',
  SEARCH_VOLUME = 'search_volume',
}

export const CAMPAIGN_COLUMNS: Record<string, Record<string, any>> = {
  [PLATFORM.SHOPEE]: {
    dimensions: [
      {
        key: 'status',
        disabled: true,
        order: 1,
      },
      {
        key: 'name',
        disabled: true,
        order: 2,
      },
      {
        key: 'product_placement',
        disabled: false,
        order: 3,
      },
      {
        key: 'bidding_strategy',
        disabled: false,
        order: 4,
      },
      {
        key: 'target_audience_type',
        disabled: false,
        order: 5,
      },
      {
        key: 'tags',
        disabled: false,
        order: 6,
      },
      {
        key: 'active_strategies',
        disabled: false,
        order: 7,
      },
      {
        key: 'daily_budget',
        disabled: false,
        order: 8,
      },
      {
        key: 'max_daily_budget',
        disabled: false,
        order: 9,
      },
      {
        key: 'date',
        disabled: false,
        order: 10,
      },
    ],
    metrics: [
      {
        key: METRIC_ENUMS.SPEND,
        disabled: false,
        order: 11,
      },
      {
        key: METRIC_ENUMS.IMPRESSIONS,
        disabled: false,
        order: 12,
      },
      {
        key: METRIC_ENUMS.CPM,
        disabled: false,
        order: 13,
      },
      {
        key: METRIC_ENUMS.CLICKS,
        disabled: false,
        order: 14,
      },
      {
        key: METRIC_ENUMS.CLICK_THROUGH_RATE,
        disabled: false,
        order: 15,
      },
      {
        key: METRIC_ENUMS.CPC,
        disabled: false,
        order: 16,
      },
      {
        key: METRIC_ENUMS.ORDERS,
        disabled: false,
        order: 17,
      },
      {
        key: METRIC_ENUMS.CPO,
        disabled: false,
        order: 18,
      },
      {
        key: METRIC_ENUMS.CONVERSION_RATE,
        disabled: false,
        order: 19,
      },
      {
        key: METRIC_ENUMS.UNITS_SOLD,
        disabled: false,
        order: 20,
      },
      {
        key: METRIC_ENUMS.PAID_GMV,
        disabled: false,
        order: 21,
      },
      {
        key: METRIC_ENUMS.ROAS,
        disabled: false,
        order: 22,
      },
      {
        key: METRIC_ENUMS.DIRECT_UNITS_SOLD,
        disabled: false,
        order: 23,
      },
      {
        key: METRIC_ENUMS.DIRECT_PAID_GMV,
        disabled: false,
        order: 24,
      },
      {
        key: METRIC_ENUMS.DIRECT_ROAS,
        disabled: false,
        order: 25,
      },
    ],
  },
  [PLATFORM.LAZADA]: {
    dimensions: [
      {
        key: 'status',
        disabled: true,
        order: 1,
      },
      {
        key: 'name',
        disabled: true,
        order: 2,
      },
      {
        key: 'active_strategies',
        disabled: false,
        order: 3,
      },
      {
        key: 'daily_budget',
        disabled: false,
        order: 4,
      },
      {
        key: 'max_daily_budget',
        disabled: false,
        order: 5,
      },
      {
        key: 'date',
        disabled: false,
        order: 6,
      },
    ],
    metrics: [
      {
        key: METRIC_ENUMS.SPEND,
        disabled: false,
        order: 7,
      },
      {
        key: METRIC_ENUMS.IMPRESSIONS,
        disabled: false,
        order: 8,
      },
      {
        key: METRIC_ENUMS.CPM,
        disabled: false,
        order: 9,
      },
      {
        key: METRIC_ENUMS.CLICKS,
        disabled: false,
        order: 10,
      },
      {
        key: METRIC_ENUMS.CLICK_THROUGH_RATE,
        disabled: false,
        order: 11,
      },
      {
        key: METRIC_ENUMS.CPC,
        disabled: false,
        order: 12,
      },
      {
        key: METRIC_ENUMS.ORDERS,
        disabled: false,
        order: 13,
      },
      {
        key: METRIC_ENUMS.CPO,
        disabled: false,
        order: 14,
      },
      {
        key: METRIC_ENUMS.CONVERSION_RATE,
        disabled: false,
        order: 15,
      },
      {
        key: METRIC_ENUMS.UNITS_SOLD,
        disabled: false,
        order: 16,
      },
      {
        key: METRIC_ENUMS.PAID_GMV,
        disabled: false,
        order: 17,
      },
      {
        key: METRIC_ENUMS.ROAS,
        disabled: false,
        order: 18,
      },
      {
        key: METRIC_ENUMS.ADD_TO_CART_UNITS,
        disabled: false,
        order: 19,
      },
      {
        key: METRIC_ENUMS.DIRECT_UNITS_SOLD,
        disabled: false,
        order: 20,
      },
      {
        key: METRIC_ENUMS.DIRECT_PAID_GMV,
        disabled: false,
        order: 21,
      },
      {
        key: METRIC_ENUMS.DIRECT_ROAS,
        disabled: false,
        order: 22,
      },
    ],
  },
  keywords: {
    dimensions: [
      {
        key: 'keyword_normalize',
        disabled: true,
        order: 0,
      },
      {
        key: 'status',
        disabled: true,
        order: 2,
      },
      {
        key: 'name',
        disabled: true,
        order: 3,
      },
      {
        key: 'channel',
        disabled: false,
        order: 4,
      },
      {
        key: 'match_type',
        disabled: false,
        order: 5,
      },
      {
        key: 'strategies',
        disabled: false,
        order: 6,
      },
      {
        key: 'bid_price',
        disabled: false,
        order: 7,
      },
      {
        key: 'suggested_bid_price',
        disabled: false,
        order: 8,
      },
    ],
    metrics: [
      {
        key: METRIC_ENUMS.AVERAGE_RANKING,
        disabled: false,
        order: 9,
      },
      {
        key: METRIC_ENUMS.SPEND,
        disabled: false,
        order: 10,
      },
      {
        key: METRIC_ENUMS.IMPRESSIONS,
        disabled: false,
        order: 11,
      },
      {
        key: METRIC_ENUMS.CLICKS,
        disabled: false,
        order: 12,
      },
      {
        key: METRIC_ENUMS.CLICK_THROUGH_RATE,
        disabled: false,
        order: 13,
      },
      {
        key: METRIC_ENUMS.CPC,
        disabled: false,
        order: 14,
      },
      {
        key: METRIC_ENUMS.ORDERS,
        disabled: false,
        order: 15,
      },
      {
        key: METRIC_ENUMS.CONVERSION_RATE,
        disabled: false,
        order: 16,
      },
      {
        key: METRIC_ENUMS.PAID_GMV,
        disabled: false,
        order: 17,
      },
      {
        key: METRIC_ENUMS.ROAS,
        disabled: false,
        order: 18,
      },
      {
        key: METRIC_ENUMS.CPO,
        disabled: false,
        order: -1,
      },
      {
        key: METRIC_ENUMS.CPM,
        disabled: false,
        order: -1,
      },
      {
        key: METRIC_ENUMS.DIRECT_PAID_GMV,
        disabled: false,
        order: -1,
      },
      {
        key: METRIC_ENUMS.DIRECT_ROAS,
        disabled: false,
        order: -1,
      },
      {
        key: METRIC_ENUMS.DIRECT_UNITS_SOLD,
        disabled: false,
        order: -1,
      },
      {
        key: METRIC_ENUMS.AOV,
        disabled: false,
        order: -1,
      },
      {
        key: METRIC_ENUMS.UNITS_SOLD,
        disabled: false,
        order: -1,
      },
      {
        key: METRIC_ENUMS.SEARCH_VOLUME,
        disabled: true,
        order: 1,
      },
    ],
  },
}

export const DEFAULT_CHANNELS: Record<string, Record<string, string>> = {
  [PLATFORM.LAZADA]: {
    lazada_sponsored_discovery: 'main-tabs.channels.lazada_sponsored_discovery',
  },
  [PLATFORM.SHOPEE]: {
    product_ads: 'main-tabs.channels.shopee_product_ads',
    shop_ads: 'main-tabs.channels.shopee_shop_ads',
  },
}
export const DEFAULT_KEYWORD_STATUS: Record<string, string> = {
  ongoing: 'status.ongoing',
  paused: 'status.paused',
  reserved: 'status.reserved',
}
export const ADVANCED_KEYWORD_STATUS: Record<string, string> = {
  ...DEFAULT_KEYWORD_STATUS,
  pausing: 'status.pausing',
}
export const FORMULAR_METRICS: Record<string, any> = {
  [METRIC_ENUMS.ROAS]: {
    columns: [METRIC_ENUMS.PAID_GMV, METRIC_ENUMS.SPEND],
    expression: 'startVal / endVal',
  },
  [METRIC_ENUMS.DIRECT_ROAS]: {
    columns: [METRIC_ENUMS.DIRECT_PAID_GMV, METRIC_ENUMS.SPEND],
    expression: 'startVal / endVal',
  },
  [METRIC_ENUMS.CPC]: {
    columns: [METRIC_ENUMS.SPEND, METRIC_ENUMS.CLICKS],
    expression: 'startVal / endVal',
  },
  [METRIC_ENUMS.CPO]: {
    columns: [METRIC_ENUMS.SPEND, METRIC_ENUMS.ORDERS],
    expression: 'startVal / endVal',
  },
  [METRIC_ENUMS.AOV]: {
    columns: [METRIC_ENUMS.PAID_GMV, METRIC_ENUMS.ORDERS],
    expression: 'startVal / endVal',
  },
  [METRIC_ENUMS.CONVERSION_RATE]: {
    columns: ['conversions', METRIC_ENUMS.CLICKS],
    expression: '(startVal / endVal) * 100',
  },
  [METRIC_ENUMS.CLICK_THROUGH_RATE]: {
    columns: [METRIC_ENUMS.CLICKS, METRIC_ENUMS.IMPRESSIONS],
    expression: '(startVal / endVal) * 100',
  },
  [METRIC_ENUMS.CPM]: {
    columns: [METRIC_ENUMS.SPEND, METRIC_ENUMS.IMPRESSIONS],
    expression: 'startVal / (endVal / 1000)',
  },
}
